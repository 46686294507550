export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const casinoTabs = {
  popularGames: {
    VivoAndarBahar: {
      redirectUrl: "/casino/vivo-andar-bahar",
      code: "228000",
      casino: "Ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/vivoAndarBahar.jpg",
      alt: "",
      name: "Andar Bahar",
      providerName: "Vivo",
    },
    VivoTeenPatti: {
      redirectUrl: "/casino/vivo-teen-patti",
      code: "228000",
      casino: "Ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/Vivo/TeenPatti1.jpg",
      alt: "",
      name: "Teen Patti",
      providerName: "Vivo",
    },
    AndarBahar: {
      redirectUrl: "/casino/ezg-andar-bahar",
      code: "228000",
      casino: "Ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/AndarBahar.jpg",
      alt: "",
      name: "Andar Bahar",
      providerName: "Ezugi",
    },
    UltimateAndarBahar: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "",
      casino: "Ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/UltimateAndarBahar.jpg",
      alt: "",
      name: "Ultimate Andar Bahar",
      providerName: "Ezugi",
    },
    aviator: {
      redirectUrl: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Aviator.gif",
      alt: "",
      name: "Aviator",
      providerName: "Spribe",
    },
    AndarBahar: {
      redirectUrl: "/casino/ezg-andar-bahar",
      code: "228000",
      casino: "Ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/AndarBahar.jpg",
      alt: "",
      name: "Andar Bahar",
      providerName: "Ezugi",
    },
    OTTAndarBahar: {
      redirectUrl: "/casino/ezg-casino-marina-andar-bahar",
      code: "328000",
      casino: "Ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/OTTAndarBahar.jpg",
      alt: "",
      name: "Casino Marina Andar Bahar",
      providerName: "Ezugi",
    },
    AutoRoulette: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "5001",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/AutoRoulette.jpg",
      alt: "",
      name: "Auto Roulette",
      providerName: "Ezugi",
    },
    SpeedRoulette: {
      redirectUrl: "/casino/ezg-speed-roulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/speedroulette.jpg",
      alt: "",
      name: "Speed Roulette",
      providerName: "Ezugi",
    },
    // LightningRoulette: {
    //   redirectUrl: "/casino/ezgevo-lightning-roulette",
    //   code: "1000092",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/roulette/Lightiningroulette.jpg",
    //   alt: "",
    //   name: "Lightning Roulette",
    //   providerName: "Evolutio",
    // },
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "",
      casino: "Ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dragontiger/DragonTiger.jpg",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Ezugi",
    },
    NoComBaccarat: {
      redirectUrl: "/casino/ezg-no-commission-baccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/nocommissionbaccarat.jpg",
      alt: "",
      name: "No Commission Baccarat",
      providerName: "Ezugi",
    },
    Lucky7: {
      redirectUrl: "/casino/ezg-lucky-7",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Lucky7.jpg",
      alt: "",
      name: "Lucky 7",
      providerName: "Ezugi",
    },
    OnedayTeenpatti: {
      redirectUrl: "/casino/ezg-one-day-teen-patti",
      code: "227102",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Onedayteenpatti.jpg",
      alt: "",
      name: "One Day Teen Patti",
      providerName: "Ezugi",
    },
    CricketWar: {
      redirectUrl: "/casino/ezg-cricket-war",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/Cricket-War.jpg",
      alt: "",
      name: "Cricket War",
      providerName: "Ezugi",
    },
    // CrazyTime: {
    //   redirectUrl: "/casino/ezgevo-crazy-time",
    //   code: "1000148",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/tvshows/CrazyTime.jpg",
    //   alt: "",
    //   name: "Crazy Time",
    //   providerName: "Evolution",
    // },
    name: "popularGames",
  },

  andarbahartab: {
    AndarBahar: {
      redirectUrl: "/casino/ezg-andar-bahar",
      code: "228000",
      casino: "Ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/AndarBahar.jpg",
      alt: "",
      name: "Andar Bahar",
      providerName: "Ezugi",
    },
    UltimateAndarBahar: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "",
      casino: "Ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/UltimateAndarBahar.jpg",
      alt: "",
      name: "Ultimate Andar Bahar",
      providerName: "Ezugi",
    },
    OTTAndarBahar: {
      redirectUrl: "/casino/ezg-casino-marina-andar-bahar",
      code: "328000",
      casino: "Ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/OTTAndarBahar.jpg",
      alt: "",
      name: "Casino Marina Andar Bahar",
      providerName: "Ezugi",
    },
    name: "andarbahartab",
  },

  roulettetab: {
    AutoRoulette: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "5001",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/AutoRoulette.jpg",
      alt: "",
      name: "Auto Roulette",
      providerName: "Ezugi",
    },
    SpeedAutoRoulette: {
      redirectUrl: "/casino/ezg-speed-auto-roulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/Speedautoroullete.jpg",
      alt: "",
      name: "Speed Auto Roulette",
      providerName: "Ezugi",
    },
    NamasteRoulette: {
      redirectUrl: "/casino/ezg-namaste-roulette",
      code: "221005",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/Namasteroulette.jpg",
      alt: "",
      name: "Namaste Roulette",
      providerName: "Ezugi",
    },
    UltimateRoulette: {
      redirectUrl: "/casino/ezg-ultimate-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/UltimateRoulette.jpg",
      alt: "",
      name: "Ultimate Roulette",
      providerName: "Ezugi",
    },
    // AutoRouletteEvo: {
    //   redirectUrl: "/casino/ezgevo-auto-roulette",
    //   code: "",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/roulette/AutorouletteEvo.jpg",
    //   alt: "",
    //   name: "Auto Roulette-EVO",
    //   providerName: "Evolutio",
    // },
    // LightningRoulette: {
    //   redirectUrl: "/casino/ezgevo-lightning-roulette",
    //   code: "1000092",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/roulette/Lightiningroulette.jpg",
    //   alt: "",
    //   name: "Lightning Roulette",
    //   providerName: "Evolutio",
    // },
    // SpeedAutoRouletteEvo: {
    //   redirectUrl: "/casino/ezgevo-speed-auto-roulette",
    //   code: "1000103",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/roulette/Speedautoroullete.jpg",
    //   alt: "",
    //   name: "Speed Auto Roulette",
    //   providerName: "Evolutio",
    // },
    name: "roulettetab",
  },

  dragontigertab: {
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "",
      casino: "Ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dragontiger/DragonTiger.jpg",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Ezugi",
    },
    // DragonTigerEvo: {
    //   redirectUrl: "/casino/ezgevo-dragon-tiger-evo",
    //   code: "",
    //   casino: "Evolution",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/dragontiger/DragonTigerEvo.jpg",
    //   alt: "",
    //   name: "Dragon Tiger EVO",
    //   providerName: "Ezugi",
    // },
    name: "dragontigertab",
  },

  baccarattab: {
    Baccarat: {
      redirectUrl: "/casino/ezg-baccarat",
      code: "100",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/Baccarat01.jpg",
      alt: "",
      name: "Baccarat",
      providerName: "Ezugi",
    },
    FortuneBaccarat: {
      redirectUrl: "/casino/ezg-fortune-baccarat",
      code: "102",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/FortuneBaccarat.jpg",
      alt: "",
      name: "Fortune Baccarat",
      providerName: "Ezugi",
    },
    NoComBaccarat: {
      redirectUrl: "/casino/ezg-no-commission-baccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/nocommissionbaccarat.jpg",
      alt: "",
      name: "No Commission Baccarat",
      providerName: "Ezugi",
    },
    SpeedCricketBaccarat: {
      redirectUrl: "/casino/ezg-speed-cricket-baccarat",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/Speedcricketbaccarat.jpg",
      alt: "",
      name: "Speed Cricket Baccarat",
      providerName: "Ezugi",
    },
    // SpeedBaccaratA: {
    //   redirectUrl: "/casino/ezgevo-speed-baccarat-a",
    //   code: "1000021",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/baccarat/sPEEDBACCARATA.jpg",
    //   alt: "",
    //   name: "Speed Baccarat A",
    //   providerName: "Evolution",
    // },
    // NoComBaccaratEvo: {
    //   redirectUrl: "/casino/ezgevo-no-commission-baccarat-evo",
    //   code: "1000018",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/baccarat/NoComBaccaratEvo.jpg",
    //   alt: "",
    //   name: "No Commission Baccarat-EVO",
    //   providerName: "Evolution",
    // },
    name: "baccarattab",
  },

  tvShows: {
    Blackjack: {
      redirectUrl: "/casino/ezg-blackjack-1",
      code: "201",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Blackjack01.jpg",
      alt: "",
      name: "Blackjack 1",
      providerName: "Ezugi",
    },
    // CrazyTime: {
    //   redirectUrl: "/casino/ezgevo-crazy-time",
    //   code: "1000148",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/tvshows/CrazyTime.jpg",
    //   alt: "",
    //   name: "Crazy Time",
    //   providerName: "Evolution",
    // },
    // Megaball: {
    //   redirectUrl: "/casino/ezgevo-mega-ball",
    //   code: "1000141",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/tvshows/Megaball.jpg",
    //   alt: "",
    //   name: "Megaball",
    //   providerName: "Evolution",
    // },
    // LightningDice: {
    //   redirectUrl: "/casino/ezgevo-lightning-dice",
    //   code: "1000076",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/othergames/LightningDice.jpg",
    //   alt: "",
    //   name: "Lightning Dice",
    //   providerName: "Evolution",
    // },
    name: "tvShows",
  },

  blackjacktab: {
    // BlackjackSilverD: {
    //   redirectUrl: "/casino/ezgevo-blackjack-silver-d",
    //   code: "1000044",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/blackjack/Blackjack-Silver-D.jpg",
    //   alt: "",
    //   name: "Blackjack Silver D",
    //   providerName: "Evolution",
    // },
    // BlackjackVIPE: {
    //   redirectUrl: "/casino/ezgevo-blackjack-vip-e",
    //   code: "1000052",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/blackjack/Blackjack-VIP-E.jpg",
    //   alt: "",
    //   name: "Blackjack VIP E",
    //   providerName: "Evolution",
    // },
    SalonPriveBlackjackC: {
      redirectUrl: "/casino/ezg-salon-prive-blackjack",
      code: "1000070",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Salon-Prive-Blackjack-C.jpg",
      alt: "",
      name: "Salon Prive Blackjack",
      providerName: "Evolution",
    },
    // SpeedBlackjackD: {
    //   redirectUrl: "/casino/ezgevo-speed-blackjack-d",
    //   code: "1000033",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/blackjack/Speed-Blackjack-D1.jpg",
    //   alt: "",
    //   name: "Speed Blackjack D",
    //   providerName: "Evolution",
    // },
    // BlackjackVIPL: {
    //   redirectUrl: "/casino/ezgevo-blackjack-vip-l",
    //   code: "1000056",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/blackjack/Blackjack-VIP-L.jpg",
    //   alt: "",
    //   name: "Blackjack VIP L",
    //   providerName: "Evolution",
    // },
    BlackjackGold3: {
      redirectUrl: "/casino/ez-blackjack-gold-3",
      code: "203",
      casino: "Ezugi-WCO",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Blackjack-Gold-3.jpg",
      alt: "",
      name: " Blackjack Gold 3",
      providerName: "Ezugi",
    },
    // ClassicSpeedBlackjack3: {
    //   redirectUrl: "/casino/ezgevo-classic-speed-blackjack-3",
    //   code: "1000228",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/blackjack/Speed-Blackjack-3.jpg",
    //   alt: "",
    //   name: " Classic Speed Blackjack 3  ",
    //   providerName: "Evolution",
    // },
    name: "blackjacktab",
  },

  pokertab: {
    // Hand2CasinoHoldem: {
    //   redirectUrl: "/casino/ezgevo-2-hand-casino-holdem",
    //   code: "1000073",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/poker/2handcasinoholem.jpg",
    //   alt: "",
    //   name: "2 Hand Casino Holdem",
    //   providerName: "Evolution",
    // },
    BetonTeenPatti: {
      redirectUrl: "/casino/ezg-bet-on-teen-patti",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Betonteenpatti.jpg",
      alt: "",
      name: "Bet on Teen Patti",
      providerName: "Ezugi",
    },
    Onedayteenpatti1: {
      redirectUrl: "/casino/ezg-one-day-teen-patti",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Onedayteenpatti.jpg",
      alt: "",
      name: "One Day Teen Patti",
      providerName: "Ezugi",
    },
    // SideBetCity: {
    //   redirectUrl: "/casino/ezgevo-side-bet-city",
    //   code: "1000115",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/poker/Sidebetcity.jpg",
    //   alt: "",
    //   name: "Side Bet City",
    //   providerName: "Evolution",
    // },
    TeenPatti3card: {
      redirectUrl: "/casino/ezg-teen-patti-3-card",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Teenpatti3card.jpg",
      alt: "",
      name: "Teen Patti 3 Card",
      providerName: "Ezugi",
    },
    // Ultimatetexasholdem: {
    //   redirectUrl: "/casino/ezgevo-ultimate-texas-holdem",
    //   code: "1000151",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/poker/ultimatetaxasholden.jpg",
    //   alt: "",
    //   name: "Ultimate Texas Holdem",
    //   providerName: "Evolution",
    // },
    name: "pokertab",
  },

  spribe: {
    aviator: {
      redirectUrl: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Aviator.gif",
      alt: "",
      name: "Aviator",
      providerName: "Spribe",
    },
    mines: {
      redirectUrl: "/casino/spribe/mines",
      code: "mines",
      casino: "spribe",
      provider: "mines",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Mines.jpg",
      alt: "",
      name: "Mines",
      providerName: "Spribe",
    },
    goal: {
      redirectUrl: "/casino/spribe/goal",
      code: "goal",
      casino: "spribe",
      provider: "goal",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Goal.jpg",
      alt: "",
      name: "Goal",
      providerName: "Spribe",
    },
    dice: {
      redirectUrl: "/casino/spribe/dice",
      code: "dice",
      casino: "spribe",
      provider: "dice",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Dice.jpg",
      alt: "",
      name: "Dice",
      providerName: "Spribe",
    },
    plinko: {
      redirectUrl: "/casino/spribe/plinko",
      code: "plinko",
      casino: "spribe",
      provider: "plinko",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Plinko.jpg",
      alt: "",
      name: "Plinko",
      providerName: "Spribe",
    },
    miniroulette: {
      redirectUrl: "/casino/spribe/miniroulette",
      code: "mini-roulette",
      casino: "spribe",
      provider: "mini-roulette",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/MiniROulette.jpg",
      alt: "",
      name: "Mini Roulette",
      providerName: "Spribe",
    },
    hilo: {
      redirectUrl: "/casino/spribe/hilo",
      code: "hi-lo",
      casino: "spribe",
      provider: "hi-lo",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Hilo.jpg",
      alt: "",
      name: "Hilo",
      providerName: "Spribe",
    },
    name: "spribe",
  },

  supernowa: {
    RNGCasinoQueen: {
      redirectUrl: "/casino/sn-rng-casino-queen",
      code: "RCQ",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGCasinoQueen.jpg",
      alt: "",
      name: "RNG Casino Queen",
      providerName: "Supernowa",
    },
    RNGCasinoQueen2020: {
      redirectUrl: "/casino/sn-rng-casino-queen-2020",
      code: "RCQ20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGCasinoQueen2020.jpg",
      alt: "",
      name: "RNG Casino Queen 2020",
      providerName: "Supernowa",
    },
    // RNGCricket2020: {
    //   redirectUrl: "/casino/sn-rng-cricket-2020",
    //   code: "RCKT20",
    //   casino: "wco",
    //   provider: "SN",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/supernowa/RNGCricket2020.jpg",
    //   alt: "",
    //   name: "RNG Cricket 2020",
    //   providerName: "Supernowa",
    // },
    RNGDragonTiger: {
      redirectUrl: "/casino/sn-rng-dragon-tiger",
      code: "VDT",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNG-Dragon-Tiger.jpg",
      alt: "",
      name: "RNG Dragon Tiger",
      providerName: "Supernowa",
    },
    RNGDragonTiger2020: {
      redirectUrl: "/casino/sn-rng-dragon-tiger-2020",
      code: "VDT20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGDragonTiger2020.jpg",
      alt: "",
      name: "RNG Dragon Tiger 2020",
      providerName: "Supernowa",
    },
    RNGJoker: {
      redirectUrl: "/casino/sn-rng-joker",
      code: "VJKR",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGJoker.jpg",
      alt: "",
      name: "RNG Joker",
      providerName: "Supernowa",
    },
    RNGKingRace: {
      redirectUrl: "/casino/sn-rng-king-race",
      code: "VCR",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGKingRace.jpg",
      alt: "",
      name: "RNG King Race",
      providerName: "Supernowa",
    },
    RNGLucky7: {
      redirectUrl: "/casino/sn-rng-lucky-7",
      code: "VUD7",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGLucky7.jpg",
      alt: "",
      name: "RNG Lucky 7",
      providerName: "Supernowa",
    },
    RNGTeenPatti: {
      redirectUrl: "/casino/sn-rng-teen-patti",
      code: "VTP",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGTeenPatti.jpg",
      alt: "",
      name: "RNG Teen Patti",
      providerName: "Supernowa",
    },
    RNGTeenPatti2020: {
      redirectUrl: "/casino/sn-rng-teen-patti-2020",
      code: "VTP20",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGTeenPatti2020.jpg",
      alt: "",
      name: "RNG Teen Patti 2020",
      providerName: "Supernowa",
    },
    RNGWorliMatka: {
      redirectUrl: "/casino/sn-rng-worli-matka",
      code: "VWM",
      casino: "wco",
      provider: "SN",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/supernowa/RNGWorliMatka.jpg",
      alt: "",
      name: "RNG Worli Matka",
      providerName: "Supernowa",
    },
    name: "supernowa",
  },

  vivo: {
    VABacarat1: {
      redirectUrl: "/casino/vivo-va-bacarat-1",
      imgUrl: "./images/Vivo/VABacarat1.jpg",
      name: "VA Bacarat 1",
      providerName: "Vivo",
    },
    VABacarat2: {
      redirectUrl: "/casino/vivo-va-bacarat-2",
      imgUrl: "./images/Vivo/VABacarat2.jpg",
      name: "VA Bacarat 2",
      providerName: "Vivo",
    },
    VABacarat3: {
      redirectUrl: "/casino/vivo-va-bacarat-3",
      imgUrl: "./images/Vivo/VABacarat3.jpg",
      name: "VA Bacarat 3",
      providerName: "Vivo",
    },
    VABacarat4: {
      redirectUrl: "/casino/vivo-va-bacarat-4",
      imgUrl: "./images/Vivo/VABacarat4.jpg",
      name: "VA Bacarat 4",
      providerName: "Vivo",
    },
    VABacarat5: {
      redirectUrl: "/casino/vivo-va-bacarat-5",
      imgUrl: "./images/Vivo/VABacarat5.jpg",
      name: "VA Bacarat 5",
      providerName: "Vivo",
    },
    OracleBlazeRoulette: {
      redirectUrl: "/casino/vivo-oracle-blaze-roulette",
      imgUrl: "./images/Vivo/OracleBlazeRoulette.jpg",
      name: "Oracle Blaze Roulette",
      providerName: "Vivo",
    },
    PortomasoRoulette: {
      redirectUrl: "/casino/vivo-portomaso-roulette",
      imgUrl: "./images/Vivo/PortomasoRoulette.jpg",
      name: "Portomaso Roulette",
      providerName: "Vivo",
    },
    LasVegasRoulette: {
      redirectUrl: "/casino/vivo-las-vegas-roulette",
      imgUrl: "./images/Vivo/LasVegasRoulette.jpg",
      name: "Las Vegas Roulette",
      providerName: "Vivo",
    },
    MacauBaccarat: {
      redirectUrl: "/casino/vivo-macau-baccarat",
      imgUrl: "./images/Vivo/MacauBaccarat.jpg",
      name: "Macau Baccarat",
      providerName: "Vivo",
    },
    LasVegasBlackjack: {
      redirectUrl: "/casino/vivo-las-vegas-blackjack",
      imgUrl: "./images/Vivo/lasvegasblackjack.jpg",
      name: "Las Vegas Blackjack",
      providerName: "Vivo",
    },
    DragonTiger: {
      redirectUrl: "/casino/vivo-dragon-tiger",
      imgUrl: "./images/Vivo/DragonTiger.jpg",
      name: "Dragon Tiger",
      providerName: "Vivo",
    },
    TeenPattiVivo: {
      redirectUrl: "/casino/vivo-teen-patti",
      imgUrl: "./images/Vivo/TeenPatti1.jpg",
      name: "Teen Patti",
      providerName: "Vivo",
    },
    AndarBaharVivo: {
      redirectUrl: "/casino/vivo-andar-bahar",
      imgUrl: "./images/Vivo/AndarBahar.jpg",
      name: "Andar Bahar",
      providerName: "Vivo",
    },
    name: "vivo",
  },

  kingmaker: {
    CardPoker5: {
      redirectUrl: "/casino/km-5-card-poker",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/5-card-poker.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Card Poker",
      providerName: "Kingmaker",
    },
    up7down7: {
      redirectUrl: "/casino/km-7-up-7-down",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/7-up-7-down.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "7 up 7 down",
      providerName: "Kingmaker",
    },
    cards32: {
      redirectUrl: "/casino/km-32-cards",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/32-cards.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "32 Cards",
      providerName: "Kingmaker",
    },
    AndarBahar: {
      redirectUrl: "/casino/km-andar-bahar",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/andar-bahar.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Andar Bahar",
      providerName: "Kingmaker",
    },
    Baccarat: {
      redirectUrl: "/casino/km-baccarat",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/baccarat.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Baccarat",
      providerName: "Kingmaker",
    },
    BaiBuu: {
      redirectUrl: "/casino/km-bai-buu",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/bai-buu.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Bai Buu",
      providerName: "Kingmaker",
    },
    BaiCao: {
      redirectUrl: "/casino/km-bai-cao",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/bai-cao.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Bai Cao",
      providerName: "Kingmaker",
    },
    Belangkai2: {
      redirectUrl: "/casino/km-belangkai-2",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/belangkai-2.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Belangkai 2",
      providerName: "Kingmaker",
    },
    Blackjack: {
      redirectUrl: "/casino/km-blackjack",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/blackjack.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Blackjack",
      providerName: "Kingmaker",
    },
    BolaGolek: {
      redirectUrl: "/casino/km-bola-golek",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/bola-golek.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Bola Golek",
      providerName: "Kingmaker",
    },
    BolaTangkas: {
      redirectUrl: "/casino/km-bola-tangkas",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/bola-tangkas.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Bola Tangkas",
      providerName: "Kingmaker",
    },
    BonusDice: {
      redirectUrl: "/casino/km-bonus-dice",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/bonus-dice.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Bonus Dice",
      providerName: "Kingmaker",
    },
    CardHiLo: {
      redirectUrl: "/casino/km-card-hi-lo",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/card-hi-lo.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Card Hi Lo",
      providerName: "Kingmaker",
    },
    CashRocket: {
      redirectUrl: "/casino/km-cash-rocket",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/cash-rocket.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Cash Rocket",
      providerName: "Kingmaker",
    },
    CleopatrasTreasure: {
      redirectUrl: "/casino/km-cleopatras-treasure",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/cleopatras-treasure.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Cleopatras Treasure",
      providerName: "Kingmaker",
    },
    CockfightingArena: {
      redirectUrl: "/casino/km-cockfighting-arena",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/cockfighting-arena.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Cockfighting Arena",
      providerName: "Kingmaker",
    },
    CoinPusher: {
      redirectUrl: "/casino/km-coin-pusher",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/coin-pusher.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Coin Pusher",
      providerName: "Kingmaker",
    },
    CoinToss: {
      redirectUrl: "/casino/km-coin-toss",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/coin-toss.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Coin Toss",
      providerName: "Kingmaker",
    },
    ColorGame: {
      redirectUrl: "/casino/km-color-game",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/color-game.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Color Game",
      providerName: "Kingmaker",
    },
    DotaHiLo: {
      redirectUrl: "/casino/km-dota-hi-lo",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/dota-hi-lo.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Dota Hi Lo",
      providerName: "Kingmaker",
    },
    DragonTiger2: {
      redirectUrl: "/casino/km-dragon-tiger-2",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/dragon-tiger-2.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Dragon Tiger 2",
      providerName: "Kingmaker",
    },
    EliteAviatorClub: {
      redirectUrl: "/casino/km-elite-aviator-club",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/elite-aviator-club.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Elite Aviator Club",
      providerName: "Kingmaker",
    },
    EuropeanRoulette: {
      redirectUrl: "/casino/km-european-roulette",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/european-roulette.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "European Roulette",
      providerName: "Kingmaker",
    },
    FanTvanClassic: {
      redirectUrl: "/casino/km-fan-tvan-classic",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/fan-tvan-classic.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Fan Tan Classic",
      providerName: "Kingmaker",
    },
    FishPrawnCrab2: {
      redirectUrl: "/casino/km-fish-prawn-crab-2",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/fish-prawn-crab-2.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Fish Prawn Crab 2",
      providerName: "Kingmaker",
    },
    FruitRoulette: {
      redirectUrl: "/casino/km-fruit-roulette",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/fruit-roulette.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "FruitRoulette",
      providerName: "Kingmaker",
    },
    GemsOfValhalla: {
      redirectUrl: "/casino/km-gems-of-valhalla",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/gems-of-valhalla.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Gems Of Valhalla",
      providerName: "Kingmaker",
    },
    GoldMines: {
      redirectUrl: "/casino/km-gold-mines",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/gold-mines.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Gold Mines",
      providerName: "Kingmaker",
    },
    Heist: {
      redirectUrl: "/casino/km-heist",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/heist.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "heist",
      providerName: "Kingmaker",
    },
    InterstellarRun: {
      redirectUrl: "/casino/km-interstellar-run",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/interstellar-run.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "InterstellarRun",
      providerName: "Kingmaker",
    },
    JackpotJump: {
      redirectUrl: "/casino/km-jackpot-jump",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/jackpot-jump.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Jackpot Jump",
      providerName: "Kingmaker",
    },
    JhandiMunda: {
      redirectUrl: "/casino/km-jhandi-munda",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/jhandi-munda.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Jhandi Munda",
      providerName: "Kingmaker",
    },
    KMPokDeng: {
      redirectUrl: "/casino/km-km-pok-deng",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/km-pok-deng.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "KM Pok Deng",
      providerName: "Kingmaker",
    },
    KMPowerBall: {
      redirectUrl: "/casino/km-km-power-ball",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/km-power-ball.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "KM Power Ball",
      providerName: "Kingmaker",
    },
    KMVirtualHorseRacing: {
      redirectUrl: "/casino/km-km-virtual-horse-racing",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/km-virtual-horse-racing.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "KM Virtual Horse Racing",
      providerName: "Kingmaker",
    },
    Ludo: {
      redirectUrl: "/casino/km-ludo",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/ludo.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Ludo",
      providerName: "Kingmaker",
    },
    MarbleRacing: {
      redirectUrl: "/casino/km-marble-racing",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/marble-racing.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Marble Racing",
      providerName: "Kingmaker",
    },
    MaxKeno: {
      redirectUrl: "/casino/km-max-keno",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/max-keno.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Max Keno",
      providerName: "Kingmaker",
    },
    Minesweeper: {
      redirectUrl: "/casino/km-minesweeper",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/minesweeper.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Minesweeper",
      providerName: "Kingmaker",
    },
    MonkeyKingRoulette: {
      redirectUrl: "/casino/km-monkey-king-roulette",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/monkey-king-roulette.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Monkey King Roulette",
      providerName: "Kingmaker",
    },
    OlympusGlory: {
      redirectUrl: "/casino/km-olympus-glory",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/olympus-glory.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Olympus Glory",
      providerName: "Kingmaker",
    },
    PaiKang: {
      redirectUrl: "/casino/km-pai-kang",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/pai-kang.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Pai Kang",
      providerName: "Kingmaker",
    },
    PenguinPanic: {
      redirectUrl: "/casino/km-penguin-panic",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/penguin-panic.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Penguin Panic",
      providerName: "Kingmaker",
    },
    Plinko: {
      redirectUrl: "/casino/km-plinko",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/plinko.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Plinko",
      providerName: "Kingmaker",
    },
    PokerRoulette: {
      redirectUrl: "/casino/km-poker-roulette",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/poker-roulette.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Poker Roulette",
      providerName: "Kingmaker",
    },
    Pusoy: {
      redirectUrl: "/casino/km-pusoy",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/pusoy.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Pusoy",
      providerName: "Kingmaker",
    },
    RoosterBlitz: {
      redirectUrl: "/casino/km-rooster-blitz",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/rooster-blitz.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Rooster Blitz",
      providerName: "Kingmaker",
    },
    SicBo: {
      redirectUrl: "/casino/km-sicbo",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/sicbo.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Rooster Blitz",
      providerName: "Kingmaker",
    },
    SugarBlast: {
      redirectUrl: "/casino/km-sugar-blast",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/sugar-blast.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Sugar Blast",
      providerName: "Kingmaker",
    },
    SugarBlastFrenzy: {
      redirectUrl: "/casino/km-sugar-blast-frenzy",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/sugar-blast-frenzy.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Sugar Blast Frenzy",
      providerName: "Kingmaker",
    },
    TaiXiu: {
      redirectUrl: "/casino/km-tai-xiu",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/tai-xiu.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Tai Xiu",
      providerName: "Kingmaker",
    },
    TeenPatti: {
      redirectUrl: "/casino/km-teen-patti",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/teen-patti.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Teen Patti",
      providerName: "Kingmaker",
    },
    ThaiFishPrawnCrab: {
      redirectUrl: "/casino/km-thai-fish-prawn-crab",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/thai-fish-prawn-crab.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Thai Fish Prawn Crab",
      providerName: "Kingmaker",
    },
    ThaiHiLo2: {
      redirectUrl: "/casino/km-thai-hi-lo-2",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/thai-hi-lo-2.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Thai Hi Lo 2",
      providerName: "Kingmaker",
    },
    Tongits: {
      redirectUrl: "/casino/km-tongits",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/tongits.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Tongits",
      providerName: "Kingmaker",
    },
    TripleChance: {
      redirectUrl: "/casino/km-triple-chance",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/triple-chance.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Triple Chance",
      providerName: "Kingmaker",
    },
    VideoPoker: {
      redirectUrl: "/casino/km-video-poker",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/video-poker.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Video Poker",
      providerName: "Kingmaker",
    },
    VietFishPrawnCrab: {
      redirectUrl: "/casino/km-viet-fish-prawn-crab",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/viet-fish-prawn-crab.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Viet Fish Prawn Crab",
      providerName: "Kingmaker",
    },
    VirtualAnimalRace: {
      redirectUrl: "/casino/km-virtual-animal-race",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/virtual-animal-race.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Virtual Animal Race",
      providerName: "Kingmaker",
    },
    VirtualGreyhoundRacing: {
      redirectUrl: "/casino/km-virtual-greyhound-racing",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/virtual-greyhound-racing.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Virtual Greyhound Racing",
      providerName: "Kingmaker",
    },
    VirtualTreadmillRacing: {
      redirectUrl: "/casino/km-virtual-treadmill-racing",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/virtual-treadmill-racing.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Virtual Treadmill Racing",
      providerName: "Kingmaker",
    },
    XocLightning: {
      redirectUrl: "/casino/km-xoc-lightning",
      code: "",
      casino: "",
      imgUrl: "./images/kingmaker/xoc-lightning.jpg",
      homeUrl: pageUrlGenerator("/kingmaker"),
      name: "Xoc Lightning",
      providerName: "Kingmaker",
    },

    name: "Kingmaker",
    key: "kingmaker",
  },

  // tvShows: {
  //   CrazyTime: {
  //     redirectUrl: "/casino/ezgevo-crazy-time",
  //     code: "1000148",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/tvshows/CrazyTime.jpg",
  //     alt: "",
  //     name: "Crazy Time",
  //     providerName: "Evolution",
  //   },
  //   DreamCatcher: {
  //     redirectUrl: "/casino/ezgevo-dream-catcher",
  //     code: "1000077",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/tvshows/DreamCatcher.jpg",
  //     alt: "",
  //     name: "Dream Catcher",
  //     providerName: "Evolution",
  //   },
  //   FootballStudio: {
  //     redirectUrl: "/casino/ezgevo-football-studio",
  //     code: "1000112",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/tvshows/FootballStudio.jpg",
  //     alt: "",
  //     name: "Football Studio",
  //     providerName: "Evolution",
  //   },
  //   Megaball: {
  //     redirectUrl: "/casino/ezgevo-mega-ball",
  //     code: "1000141",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/tvshows/Megaball.jpg",
  //     alt: "",
  //     name: "Megaball",
  //     providerName: "Evolution",
  //   },
  //   CashorCrash: {
  //     redirectUrl: "/casino/ezgevo-cash-or-crash",
  //     code: "1000352",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/tvshows/CashorCrash.jpg",
  //     alt: "",
  //     name: "Cash or Crash",
  //     providerName: "Evolution",
  //   },
  //   SicBo: {
  //     redirectUrl: "/casino/ezgevo-super-sic-bo",
  //     code: "224000",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/tvshows/SicBo.jpg",
  //     alt: "",
  //     name: "Super Sic Bo",
  //     providerName: "Ezugi",
  //   },
  //   UltimateSicBo: {
  //     redirectUrl: "/casino/ezg-ultimate-sic-bo",
  //     code: "224100",
  //     casino: "ezugi",
  //     provider: "",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/tvshows/UltimateSicBo.jpg",
  //     alt: "",
  //     name: "Ultimate Sic Bo",
  //     providerName: "Ezugi",
  //   },
  //   name: "tvShows",
  // },

  allGames: {
    // CrazyTime: {
    //   redirectUrl: "/casino/ezgevo-crazy-time",
    //   code: "1000148",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/tvshows/CrazyTime.jpg",
    //   alt: "",
    //   name: "Crazy Time",
    //   providerName: "Evolution",
    // },
    // DreamCatcher: {
    //   redirectUrl: "/casino/ezgevo-dream-catcher",
    //   code: "1000077",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/tvshows/DreamCatcher.jpg",
    //   alt: "",
    //   name: "Dream Catcher",
    //   providerName: "Evolution",
    // },
    // FootballStudio: {
    //   redirectUrl: "/casino/ezgevo-football-studio",
    //   code: "1000112",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/tvshows/FootballStudio.jpg",
    //   alt: "",
    //   name: "Football Studio",
    //   providerName: "Evolution",
    // },
    // Megaball: {
    //   redirectUrl: "/casino/ezgevo-mega-ball",
    //   code: "1000141",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/tvshows/Megaball.jpg",
    //   alt: "",
    //   name: "Megaball",
    //   providerName: "Evolution",
    // },
    // CashorCrash: {
    //   redirectUrl: "/casino/ezgevo/cash-or-crash",
    //   code: "1000352",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/tvshows/CashorCrash.jpg",
    //   alt: "",
    //   name: "Cash or Crash",
    //   providerName: "Evolution",
    // },
    OTTAndarBahar: {
      redirectUrl: "/casino/ez-ott-andar-bahar-marina",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/OTTAndarBahar.jpg",
      alt: "",
      name: "OTT Andar Bahar",
      providerName: "Ezugi",
    },
    // SicBo: {
    //   redirectUrl: "/casino/ezgevo-super-sic-bo",
    //   code: "224000",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/tvshows/SicBo.jpg",
    //   alt: "",
    //   name: "Super Sic Bo",
    //   providerName: "Ezugi",
    // },
    // UltimateSicBo: {
    //   redirectUrl: "/casino/ezgevo-ultimate-sic-bo",
    //   code: "224100",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/tvshows/UltimateSicBo.jpg",
    //   alt: "",
    //   name: "Ultimate SicBo",
    //   providerName: "Ezugi",
    // },
    name: "allGames",
  },
};
